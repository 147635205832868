<template>
  <div class="d-flex justify-content-center pt-5">
    <a-result
      status="success"
      title="Successfully verified your account"
      sub-title="You have been completed the verification of your email"
    >
      <!-- <template #extra>
        <a-button key="console" type="primary"> Go Console </a-button>
        <a-button key="buy"> Buy Again </a-button>
      </template> -->
    </a-result>
  </div>
</template>

<script>
import { emailForgotToken } from '@/helpers/token.js'

export default {
  async mounted() {
    try {
      if (this.$route?.query?.token) {
        const { token } = this.$route?.query
        const payload = await emailForgotToken(token)
        if (payload.id) {
          this.$store.dispatch('user/VERIFY_EMAIL_PARENT', payload)
        } else {
          this.$router.push('/')
        }
      } else {
        this.$router.push('/')
      }
    } catch (err) {
      // this.$router.push('/')
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
  },
}
</script>

<style>
</style>
